import React, {Fragment, useRef} from 'react';
import { signOut } from 'aws-amplify/auth';
import {
  CommsCheckResult,
  XrdlgData, XrinvData,
  XritmData,
} from '../../types/comms-check';
import TextInput from '../text-input/TextInput';
import {startChat} from '../chat/chat-jq';
import {
  buildGetXmlRequest,
  buildXrdlgRequest,
  buildXrinvRequest,
  buildXritmRequest, getAuthTokenOrLogOut,
  validateMpxn,
  validateMsn,
} from '../util';
import { homePageInitialState, useHomePageContext } from '../../context/HomePageContext';
import { Inputs, useInputsContext } from '../../context/InputsContext';
import commsCheckInitialData from '../../context/CommsCheckContext';
import {proxy500L1error, proxy500L2error} from "./util";

export default function CommsCheck() {
  const { inputs, setInputs } = useInputsContext();
  const { homePageState, setHomePageState } = useHomePageContext();
  const [statusScreen, setStatusScreen] = React.useState<'SUCCESS' | 'FAIL' | 'LOAD' | ''>('');
  const [failScreenData, setFailScreenData] = React.useState<any>({});
  const [commsCheckResult, setCommsCheckResult] = React.useState<CommsCheckResult>({
    xrinv: {
      data: [],
      state: null,
    },
    xritm: {
      data: [],
      state: null,
    },
    xrdlg: {
      data: [],
      state: null,
    },
  });

  const getXmlInitialTimeout = parseInt(`${process.env.REACT_APP_COMMS_CHECK_GET_XML_INITIAL_TIMEOUT}`, 10);
  const getXmlTimeout = parseInt(`${process.env.REACT_APP_COMMS_CHECK_GET_XML_TIMEOUT}`, 10);
  const maxRetries = parseInt(`${process.env.REACT_APP_COMMS_CHECK_GET_XML_MAX_RETRIES}`, 10);

  let retriesXrinv = 0;
  let retriesXritm = 0;
  let retriesXrdlg = 0;
  // This should always be updated through the updateProgress function to avoid inconsistent state
  const inProgress = useRef(false);

  function init() {
    retriesXrinv = 0;
    retriesXritm = 0;
    retriesXrdlg = 0;
    updateProgress(false);
    setCommsCheckResult(commsCheckInitialData);
    setFailScreenData({});
    setStatusScreen('');
    setHomePageState({
      ...homePageInitialState,
      brandSelected: homePageState.brandSelected,
    });
  }

  /**
   * Updates both, the inProgress variable used in the JS part of the component and the
   * automationInProgress state variable used in the HTML part of the component.
   *
   * These two variable should only be updated through this function to avoid inconsistent state.
   *
   * @param state - the new state
   */
  function updateProgress(state: boolean) {
    inProgress.current = state;
    setHomePageState({
      ...homePageState,
      automationInProgress: state,
    });
  }

  const sendSingleGenerateXmlXrinvXritm = (ins: Inputs, resultAcc: CommsCheckResult, token: string) => {
    const mpxnValidationError = validateMpxn(ins.mpxn);
    const msnValidationError = validateMsn(ins.msn);
    setInputs({
      ...ins,
      mpxnError: mpxnValidationError,
      msnError: msnValidationError,
    });

    if (mpxnValidationError || msnValidationError) {
      return;
    }

    const request = buildXrinvRequest(ins.mpxn, token);
    updateProgress(true);
    setStatusScreen('LOAD');
    fetch(`${process.env.REACT_APP_SINGLE_GENERATE_XML_PROXY_LAMBDA_URL}/xrinv`, request)
      .then((httpResponse) => {
        if (httpResponse.status === 500) {
          inProgress.current = false;
          checkCompleted(proxy500L1error);
          throw new Error('L1 500 proxy error.');
        }
        return httpResponse.json()
      })
      .then((l1Response) => {
        console.warn("L1 XRINV 200 proxy success, L2 starts", l1Response);
        if (inProgress.current) {
          setTimeout(() => sendSingleGetXmlXrinv(l1Response.atomL1response, ins.mpxn, resultAcc, token), getXmlInitialTimeout);
        }
      })
      .catch((e) => {
        console.warn("L1 XRINV 500 proxy fail, L2 WONT start", e);
      });

    const xritmRequest = buildXritmRequest(ins.mpxn, ins.msn, token);
    fetch(`${process.env.REACT_APP_SINGLE_GENERATE_XML_PROXY_LAMBDA_URL}/xritm`, xritmRequest)
      .then((httpResponse) => {
        if (httpResponse.status === 500) {
          inProgress.current = false;
          checkCompleted(proxy500L1error);
          throw new Error('L1 500 proxy error.');
        }
        return httpResponse.json();
      })
      .then((l1Response) => {
        console.warn("L1 XRITM 200 proxy success, L2 starts", l1Response);
        if (inProgress.current) {
          setTimeout(() => sendSingleGetXmlXritm(l1Response.atomL1response, resultAcc, token), getXmlInitialTimeout);
        }
      })
      .catch((e) => {
        console.warn("L1 XRITM 500 proxy fail, L2 WONT start", e);
      });
  };

  const sendSingleGenerateXmlXrdlg = (request: any, resultAcc: CommsCheckResult, token: string) => {
    fetch(`${process.env.REACT_APP_SINGLE_GENERATE_XML_PROXY_LAMBDA_URL}/xrdlg`, request)
      .then((httpResponse) => {
        if (httpResponse.status === 500) {
          inProgress.current = false;
          checkCompleted(proxy500L1error);
          throw new Error('L1 500 proxy error');
        }
        return httpResponse.json();
      })
      .then((l1Response) => {
        console.warn("L1 XRDLG 200 proxy success, L2 starts", l1Response);
        if (inProgress.current) {
          setTimeout(() => sendSingleGetXmlXrdlg(l1Response.atomL1response, resultAcc, token), getXmlInitialTimeout);
        }
      })
      .catch((e) => {
        console.warn("L1 XRDLG 500 proxy fail, L2 WONT start", e);
      });
  };

  const sendSingleGetXmlXrinv = (data: any, mpxn: string, resultAcc: CommsCheckResult, token: string) => {
    if (!inProgress.current) {
      return;
    }
    const xrinvGetXmlRequest = buildGetXmlRequest(data, token);
    fetch(`${process.env.REACT_APP_SINGLE_GET_XML_PROXY_LAMBDA_URL}/xrinv`, xrinvGetXmlRequest)
      .then((httpResponse) => {
        if (httpResponse.status === 500) {
          if (inProgress.current && maxRetries > retriesXrinv) {
            retriesXrinv += 1;
            setTimeout(() => sendSingleGetXmlXrinv(data, mpxn, resultAcc, token), getXmlTimeout);
            throw new Error('L2 XRINV 500 proxy error, retrying');
          } else {
            inProgress.current = false;
            checkCompleted(proxy500L2error);
            throw new Error('L2 XRINV 500 proxy error, polling will end');
          }
        }
        return httpResponse.json();
      })
      .then((l2Response) => {
        if (l2Response.bolQueryState === 'FAIL') {
          resultAcc.xrinv = {
            state: 'FAIL',
            data: [],
          };
          resultAcc.xrdlg = {
            state: 'NOT_STARTED',
            data: [],
          };

          retriesXrinv = 0;
          checkCompleted(resultAcc);
        }

        if (l2Response.bolQueryState === 'PROCESSING') {
          if (inProgress.current && maxRetries > retriesXrinv) {
            retriesXrinv += 1;
            setTimeout(() => sendSingleGetXmlXrinv(data, mpxn, resultAcc, token), getXmlTimeout);
          } else {
            resultAcc.xrinv = {
              state: l2Response.bolQueryState,
              data: getXrinvData(l2Response) || [],
            };
            resultAcc.xrdlg = {
              state: 'FAIL',
              data: [],
            };

            retriesXrinv = 0;
            checkCompleted(resultAcc);
          }
        }

        if (l2Response.bolQueryState === 'SUCCESS') {
          resultAcc.xrinv = {
            state: l2Response.bolQueryState,
            data: getXrinvData(l2Response),
          };

          const chfGuid = resultAcc.xrinv.data.find((device) => device.deviceType === 'CHF')?.deviceId;
          const request = buildXrdlgRequest(mpxn, chfGuid, token);
          if (inProgress.current) {
            sendSingleGenerateXmlXrdlg(request, resultAcc, token);
          }

          retriesXrinv = 0;
        }
      })
      .catch((e) => {
        console.warn(e);
      });
  };

  const sendSingleGetXmlXritm = (data: any, resultAcc: CommsCheckResult, token: string) => {
    if (!inProgress.current) {
      return;
    }
    const xritmGetXmlRequest = buildGetXmlRequest(data, token);
    fetch(`${process.env.REACT_APP_SINGLE_GET_XML_PROXY_LAMBDA_URL}/xritm`, xritmGetXmlRequest)
      .then((httpResponse) => {
        if (httpResponse.status === 500) {
          if (inProgress.current && maxRetries > retriesXritm) {
            retriesXritm += 1;
            setTimeout(() => sendSingleGetXmlXritm(data, resultAcc, token), getXmlTimeout);
            throw new Error('L2 XRITM 500 proxy error, retrying');
          } else {
            inProgress.current = false;
            checkCompleted(proxy500L2error);
            throw new Error('L2 XRITM 500 proxy error, polling will end');
          }
        }
        return httpResponse.json()
      })
      .then((l2Response) => {
        if (l2Response.bolQueryState === 'FAIL') {
          resultAcc.xritm = {
            state: 'FAIL',
            data: [],
          };

          retriesXritm = 0;
          checkCompleted(resultAcc);
        }

        if (l2Response.bolQueryState === 'PROCESSING') {
          if (inProgress.current && maxRetries > retriesXritm) {
            retriesXritm += 1;
            setTimeout(() => sendSingleGetXmlXritm(data, resultAcc, token), getXmlTimeout);
          } else {

            resultAcc.xritm = {
              state: l2Response.bolQueryState,
              data: getXritmData(l2Response) || [],
            };

            retriesXritm = 0;
            checkCompleted(resultAcc);
          }
        }

        if (l2Response.bolQueryState === 'SUCCESS') {
          resultAcc.xritm = {
            state: l2Response.bolQueryState,
            data: getXritmData(l2Response) || [],
          };

          retriesXritm = 0;
          checkCompleted(resultAcc);
        }
      })
      .catch((e) => {
        console.warn(e);
      });
  };

  const sendSingleGetXmlXrdlg = (data: any, resultAcc: CommsCheckResult, token: string) => {
    if (!inProgress.current) {
      return;
    }
    const request = buildGetXmlRequest(data, token);
    fetch(`${process.env.REACT_APP_SINGLE_GET_XML_PROXY_LAMBDA_URL}/xrdlg`, request)
      .then((httpResponse) => {
        if (httpResponse.status === 500) {
          if (inProgress.current && maxRetries > retriesXrdlg) {
            retriesXrdlg += 1;
            setTimeout(() => sendSingleGetXmlXrdlg(data, resultAcc, token), getXmlTimeout);
            throw new Error('L2 XRDLG 500 proxy error, retrying');
          } else {
            inProgress.current = false;
            checkCompleted(proxy500L2error);
            throw new Error('L2 XRDLG 500 proxy error, polling will end');
          }
        }
        return httpResponse.json()
      })
      .then((response) => {
        if (response.bolQueryState === 'FAIL') {
          resultAcc.xrdlg = {
            state: 'FAIL',
            data: [],
          };

          retriesXrdlg = 0;
          checkCompleted(resultAcc);
        }

        if (response.bolQueryState === 'PROCESSING') {
          if (inProgress.current && maxRetries > retriesXrdlg) {
            retriesXrdlg += 1;
            setTimeout(() => sendSingleGetXmlXrdlg(data, resultAcc, token), getXmlTimeout);
          } else {
            resultAcc.xrdlg = {
              state: response.bolQueryState,
              data: [],
            };

            retriesXrdlg = 0;
            checkCompleted(resultAcc);
          }
        }

        if (response.bolQueryState === 'SUCCESS') {
          const allXrdlgResponseData = getXrdlgData(response);
          const deviceInventoryIds = resultAcc.xrinv.data.map((device) => device.deviceId);
          const otherDevices = allXrdlgResponseData
            .filter((device: XrdlgData) => !deviceInventoryIds.includes(device.deviceId))

          resultAcc.xrdlg = {
            state: response.bolQueryState,
            data: otherDevices,
          };

          allXrdlgResponseData.forEach((dlgDevice: XrdlgData) => {
            resultAcc.xrinv.data = resultAcc.xrinv.data.map((invDevice) => {
              const resultDevice: XrinvData = {
                deviceId: invDevice.deviceId,
                deviceType: invDevice.deviceType,
                deviceStatus: invDevice.deviceStatus,
                lastCommunicatedTime: invDevice.lastCommunicatedTime
              };

              if (invDevice.deviceId === dlgDevice.deviceId) {
                resultDevice.lastCommunicatedTime = dlgDevice.lastCommunicatedTime;
              }

              return resultDevice;
            });
          });

          retriesXrdlg = 0;
          checkCompleted(resultAcc);
        }
      })
      .catch((e) => {
        console.warn(e);
      });
  };

  function getXrinvData(l2Response: any) {
    return l2Response.atomL2response['0']?.xml_string?.Body?.XRINV_OUT_02?.InventoryDevice?.map((device: any) => ({
      deviceId: device.DeviceID,
      deviceType: device.DeviceType,
      deviceStatus: device.DeviceStatus,
    }));
  }

  function getXritmData(l2Response: any) {
    const readingRegister = l2Response.atomL2response['0']?.xml_string?.Body?.XRITM_OUT_02?.Readings?.TOURegister;
    if (!readingRegister) {
      return [];
    }

    if (Array.isArray(readingRegister)) {
      return readingRegister.map((register: any) => ({
        registerId: register.RegisterID,
        reading: register.Reading,
      }));
    }

    return [{
      registerId: readingRegister.RegisterID,
      reading: readingRegister.Reading,
    }];
  }

  function getXrdlgData(l2dlgResponse: any): XrdlgData[] {
    const deviceLogEntry = l2dlgResponse.atomL2response['0']?.xml_string?.Body?.XRDLG_OUT_02?.DeviceLogEntry;
    if (!deviceLogEntry) {
      return [];
    }

    if (Array.isArray(deviceLogEntry)) {
      return deviceLogEntry.map((device: any) => ({
        deviceId: device.DeviceID,
        lastCommunicatedTime: device.LastCommunicationTime,
      }));
    }

    return [{
      deviceId: deviceLogEntry.DeviceID,
      lastCommunicatedTime: deviceLogEntry.LastCommunicationTime,
    }];
  }

  function checkCompleted(data: CommsCheckResult) {
    console.log('Checking data...');
    // Check if all three requests have been completed
    if (data.xrinv.state && data.xritm.state && data.xrdlg.state) {
      if (data.xrinv.state === 'SUCCESS' && data.xritm.state === 'SUCCESS' && data.xrdlg.state === 'SUCCESS') {
        setFailScreenData({});
        setStatusScreen('SUCCESS');
        setCommsCheckResult(data);
        return;
      }

      buildFailScreenData(data);
      setStatusScreen('FAIL');
      setCommsCheckResult(data);
    }
  }

  function determineCommsCheckFailResult(data: CommsCheckResult): string {
    if (
      data.xritm.state === 'FAIL' ||
      (data.xritm.state === 'SUCCESS' && data.xrdlg.state === 'FAIL')
    ) {
      return 'Not in comms';
    }
    return 'Unable to be determined';
  }

  function buildFailScreenData(data: CommsCheckResult) {
    const result = determineCommsCheckFailResult(data);
    let action;
    let xrinvText;
    let xritmText;
    let xrdlgText;
    let information;

    if (data.xrinv.state === 'FAIL' && data.xritm.state === 'FAIL') {
      action = 'Unable to determine comms result, please check the MPxN and MSN and try again. If this issue persists, contact SST.';
      xrinvText = 'Unable to retrieve devices from the DCC';
      xritmText = `Unable to retrieve readings for MSN: ${inputs.msn}`;
      xrdlgText = 'Unable to retrieve last communicated time for the devices';
      information = 'Fail Information';
    } else if (data.xrinv.state === 'SUCCESS' && data.xritm.state === 'FAIL' && data.xrdlg.state === 'FAIL') {
      action = 'If this is the first unsuccessful response, please check the MPxN and MSN. If correct, perform a comms hub reboot and try again. If the issue persists, please contact SST.';
      xrinvText = null;
      xritmText = `Unable to retrieve readings for MSN: ${inputs.msn}`;
      xrdlgText = 'Unable to retrieve last communicated time for the devices';
      information = 'Fail Information';
    } else if (data.xrinv.state === 'FAIL' && data.xritm.state === 'SUCCESS') {
      action = 'Unable to determine comms result, please check the MPxN and MSN and try again. If this issue persists, contact SST.';
      xrinvText = 'Unable to retrieve devices from the DCC';
      xritmText = null;
      xrdlgText = 'Unable to retrieve last communicated time for the devices';
      information = 'Fail Information';
    } else if (data.xrinv.state === 'SUCCESS' && data.xritm.state === 'SUCCESS' && data.xrdlg.state === 'FAIL') {
      action = 'If this is the first unsuccessful response, please check the MPxN and MSN, and then try again. If the issue persists, contact SST.';
      xrinvText = null;
      xritmText = null;
      xrdlgText = 'Unable to retrieve last communicated time for the devices';
      information = 'Fail Information';
    } else if (data.xrinv.state === 'SUCCESS' && data.xritm.state === 'FAIL' && data.xrdlg.state === 'SUCCESS') {
      action = 'If this is the first unsuccessful response, please check the MPxN, MSN and CHF. If correct, perform a power cycle and try again. If the issue persists, please contact SST.';
      xrinvText = null;
      xritmText = `Unable to retrieve readings for MSN: ${inputs.msn}`;
      xrdlgText = null;
      information = 'Fail Information';
      // TIMEOUT cases
    } else if (data.xrinv.state === 'PROCESSING' && data.xritm.state === 'PROCESSING') {
      action = 'Unable to determine comms result, please check the MPxN and MSN and try again. If this issue persists, contact SST.';
      xrinvText = 'Unable to retrieve devices from the DCC';
      xritmText = `Unable to retrieve readings for MSN: ${inputs.msn}`;
      xrdlgText = 'Unable to retrieve last communicated time for the devices';
      information = 'Timeout Information';
    } else if (data.xrinv.state === 'PROCESSING' && data.xritm.state === 'SUCCESS') {
      action = 'Unable to determine comms result, please check the MPxN and MSN and try again. If this issue persists, contact SST.';
      xrinvText = 'Unable to retrieve devices from the DCC';
      xritmText = null;
      xrdlgText = 'Unable to retrieve last communicated time for the devices';
      information = 'Timeout Information';
    } else if (data.xrinv.state === 'SUCCESS' && data.xritm.state === 'FAIL' && data.xrdlg.state === 'PROCESSING') {
      action = 'If this is the first unsuccessful response, please check the MPxN and MSN. If correct, perform a comms hub reboot and try again. If the issue persists, please contact SST.';
      xrinvText = null;
      xritmText = `Unable to retrieve readings for MSN: ${inputs.msn}`;
      xrdlgText = 'Unable to retrieve last communicated time for the devices';
      information = 'Timeout Information';
    } else if (data.xrinv.state === 'PROCESSING' && data.xritm.state === 'FAIL') {
      action = 'Unable to determine comms result, please check the MPxN and MSN and try again. If this issue persists, contact SST.';
      xrinvText = 'Unable to retrieve devices from the DCC';
      xritmText = `Unable to retrieve readings for MSN: ${inputs.msn}`;
      xrdlgText = 'Unable to retrieve last communicated time for the devices';
      information = 'Timeout Information';
    } else {
      action = 'If this is the first unsuccessful response, please check the MPxN and MSN, and then try again. If the issue persists, contact SST.';
      xrinvText = '';
      xritmText = '';
      xrdlgText = '';
      information = 'Fail Information';
    }

    setFailScreenData({
      result,
      action,
      xrinvText,
      xritmText,
      xrdlgText,
      information,
    });
  }

  function handleMpxnChange(e: any) {
    setInputs({
      ...inputs,
      mpxn: e.target.value,
    });
  }

  function handleMsnChange(e: any) {
    setInputs({
      ...inputs,
      msn: e.target.value,
    });
  }

  function getInventoryData() {
    return commsCheckResult.xrinv.data.map((device) => buildSuccessInventoryInformationSection(
      device.deviceType,
      device.deviceStatus,
      device.deviceId,
      makeReadableTimeStamp(device.lastCommunicatedTime ?? ''),
    ));
  }

  function getInstantaneousData() {
    return <>
    {commsCheckResult.xritm.data.length
      ? <div className={'informationRow'} data-testid="info-reading-section">
        <div className={'informationSectionText'}>{`Readings for MSN: ${inputs.msn}`}</div>
        {commsCheckResult.xritm.data.map((device) => buildTouReadingsText(device))}
      </div>
      : null}
    </>;
  }

  function getDeviceLogData() {
    return <>
      {commsCheckResult.xrdlg.data.length ? <div className={'informationRow'} data-testid="info-device-log-section">
        <div className={'informationHeading'}>Other Devices Found:</div>
        {commsCheckResult.xrdlg.data.map((device) => buildOtherDevicesText(device.deviceId, makeReadableTimeStamp(device.lastCommunicatedTime)))}
      </div> : null}
    </>;
  }

  function buildOtherDevicesText(deviceId: string, deviceTimestamp: string) {
    return <Fragment key={deviceId}>
      <div className={'informationSectionText'}>{`GUID: ${deviceId}`}</div>
      <div className={'informationSectionText'}>{`LCT: ${deviceTimestamp}`}</div>
    </Fragment>;
  }

  function buildTouReadingsText(data: XritmData) {
    return <div key={data.registerId} className={'informationSectionText'}>{`${data.registerId}: ${data.reading}`}</div>;
  }

  function buildSuccessInventoryInformationSection(deviceType: string, deviceStatus: string, deviceGuid: string, lastCommunicatedTime: string) {
    return <div key={deviceGuid} className={'informationSection'} data-testid={`info-section-device-${deviceType}`}>
      <div className={'informationSectionText'}>{deviceType}:</div>
      <div className={'informationRow'}>
        <div>
          <span className={'informationSectionText'}>{`Status: ${deviceStatus}`}</span>
        </div>
        <div>
          <span className={'informationSectionText'}>{`GUID: ${deviceGuid}`}</span>
        </div>
        {lastCommunicatedTime && <div>
          <span className={'informationSectionText'}>{`LCT: ${lastCommunicatedTime}`}</span>
        </div>}
      </div>
    </div>;
  }

  function makeReadableTimeStamp(readingTimeStamp: string | null | undefined) {
    if (!readingTimeStamp) {
      return '';
    }

    const date = new Date(readingTimeStamp);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit',
    };
    return date.toLocaleDateString('en-GB', options).replace(/,/, ' ');
  }

  function buildChatData(success: boolean = false) {
    const chf = commsCheckResult.xrinv.data.find((device) => device.deviceType === 'CHF')?.deviceId;
    const chfText = chf ? `CHF: ${chf}\n` : '';
    const deviceData = commsCheckResult.xrinv.data.map((invDevice) => {
      const deviceLct = invDevice.lastCommunicatedTime ? `LCT: ${makeReadableTimeStamp(invDevice.lastCommunicatedTime)}\n\n` : '\n';

      return `${invDevice.deviceType}:
      Status: ${invDevice.deviceStatus}
      GUID: ${invDevice.deviceId}
      ${deviceLct}`;
    });

    const successfulInformation = deviceData.length ? `Successful Information:\n${deviceData.reduce((acc, device) => `${acc}${device}`)}` : '';

    if (success) {
      return successfulInformation;
    }

    const failDataXrinv = failScreenData.xrinvText ? `${failScreenData.xrinvText}\n` : '';
    const failDataXritm = failScreenData.xritmText ? `${failScreenData.xritmText}\n` : '';
    const failDataXrdlg = failScreenData.xrdlgText ? `${failScreenData.xrdlgText}\n` : '';
    const failInformation = `${failScreenData.information}:\n${failDataXrinv}${failDataXritm}${failDataXrdlg}`;

    return `MPxN: ${inputs.mpxn}
            MSN: ${inputs.msn}
            ${chfText}
            ${(failScreenData.xrinvText || failScreenData.xritmText || failScreenData.xrdlgText) ? failInformation : ''}
            ${!(failScreenData.xrinvText && failScreenData.xritmText && failScreenData.xrdlgText) ? successfulInformation : ''}`;
  }

  return (
    <>
      {
        statusScreen === 'LOAD' && <>
          <div className={'informationPanel results'}>
            <div className={'informationPanelLoading'} data-testid="info-panel-loading">
              <h1 className={'informationBoldedLoading'}>Information:</h1>
              <p className={'informationNormalLoading'}>Collecting information...</p>
              <p className={'informationNormalLoading'}>The average wait time is around 30 seconds.</p>
              <p className={'informationNormalLoading'}>This can take up to 3 minutes.</p>
            </div>
          </div>
          <div id={'result-buttons'} className={'btn-flex-container buttons-fixed'}>
            <div className={'wrap-login100 chatBtns'}>
              <div className={'container-login100-form-btn'}>
                <button id={'goBackLoadingPage'} className={'primaryBtn primaryButtonEnabled m-b-10'} onClick={init}>Go Back</button>
                <button className="secondaryBtn" id="LogoutLoadingPage" onClick={async () => { await signOut(); }}>Log Out</button>
              </div>
            </div>
          </div>
          <div className="emptySpaceForFixedButtons two-buttons-height"></div>
        </>
      }
      {
        (homePageState.automationInProgress && statusScreen === 'SUCCESS') && <>
          <div className="informationPanel m-b-10" data-testid="info-panel-success">
            <div className="m-b-10">
              <span className="resultKey">Result: </span><span className="resultValue">In Comms</span>
            </div>
            { (commsCheckResult.xrdlg.data.length !== 0) &&
              <div className="informationRow" data-testid="action-success-section">
                <div className="informationHeading">Action To Take:</div>
                <div className="informationSectionText">Other Devices Found. Please Contact SST</div>
              </div>
            }
            <div className="informationHeading">Information:</div>
            {getInventoryData()}
            {getInstantaneousData()}
            {getDeviceLogData()}
          </div>
          <div id="result-buttons" className="btn-flex-container buttons-fixed">
            <div className="wrap-login100 chatBtns">
              <div className="container-login100-form-btn">
                {
                  commsCheckResult.xrdlg.data.length ?
                    <button id="startChat" className="primaryBtn primaryButtonEnabled m-b-10" onClick={async () => {
                      const {userEmail, token} = await getAuthTokenOrLogOut();
                      if (token) {
                        const chatData = buildChatData(true);
                        startChat(chatData, userEmail, token, inputs.mpxn);
                        setTimeout(init, 100);
                      }
                    }}>Start Chat
                    </button> : null
                }
                <button id="retryCommsCheck" className="primaryBtn primaryButtonEnabled m-b-10" onClick={async () => {
                  init();
                  const {token} = await getAuthTokenOrLogOut();
                  if (token) {
                    sendSingleGenerateXmlXrinvXritm(
                      inputs,
                      {
                        xrinv: {data: [], state: null},
                        xritm: {data: [], state: null},
                        xrdlg: {data: [], state: null},
                      },
                      token
                    );
                  }
                }}>Retry Comms Check
                </button>
                <button id="goBackLoadingPage" className="secondaryBtn" onClick={init}>Ask About Something Else</button>
              </div>
            </div>
          </div>
          <div className="emptySpaceForFixedButtons three-buttons-height"></div>
        </>
      }
      {
        (homePageState.automationInProgress && statusScreen === 'FAIL') &&
        <div className="infoContainer" data-testid="info-panel-fail">
          <TextInput label="What's your MPxN?" inputName={'mpxnInput smallInput'}
                     error={inputs.mpxnError} kind="MPxN" value={inputs.mpxn} changeFunc={handleMpxnChange}/>
          <TextInput label="What's your MSN?" inputName={'msnInput smallInput'} error={inputs.msnError}
                     kind="MSN" value={inputs.msn} changeFunc={handleMsnChange}/>
          <div className="informationPanel m-b-10">
            <div className="failInformationSection">
              <div className="m-b-10">
                <span className="resultKey">Result: </span><span className="resultValue">{failScreenData.result}</span>
              </div>
              <div className="informationHeading">{`${failScreenData.information}:`}</div>
              {failScreenData.xrinvText && <div className={'informationRow'} data-testid="xrinv-text-fail">
                <div className="informationSectionText">{failScreenData.xrinvText}</div>
              </div>}
              {failScreenData.xritmText && <div className="informationRow" data-testid="xritm-text-fail">
                <div className="informationSectionText">{failScreenData.xritmText}</div>
              </div>}
              {failScreenData.xrdlgText && <div className={'informationRow'} data-testid="xrdlg-text-fail">
                <div className="informationSectionText">{failScreenData.xrdlgText}</div>
              </div>}
              <div className="informationRow" data-testid="action-fail-screen">
                <div className="informationHeading">Action To Take:</div>
                <div className="informationSectionText">{failScreenData.action}</div>
              </div>
              {!(failScreenData.xrinvText && failScreenData.xritmText && failScreenData.xrdlgText) && <div className="informationHeading" data-testid="info-devices-fail">Information:</div>}
              {!failScreenData.xrinvText && getInventoryData()}
              {!failScreenData.xritmText && getInstantaneousData()}
              {!failScreenData.xrdlgText && getDeviceLogData()}
            </div>
          </div>
          <div id="result-buttons" className="btn-flex-container buttons-fixed">
            <div className="wrap-login100 chatBtns">
              <div className="container-login100-form-btn">
                <button id={'retryCommsCheck'} className={'primaryBtn primaryButtonEnabled m-b-10'} onClick={async () => {
                  const {token} = await getAuthTokenOrLogOut();
                  if (token) {
                    sendSingleGenerateXmlXrinvXritm(
                      inputs,
                      {
                        xrinv: {data: [], state: null},
                        xritm: {data: [], state: null},
                        xrdlg: {data: [], state: null},
                      },
                      token
                    );
                  }
                }}>Retry Comms Check
                </button>
                <button id="startChat" className="secondaryBtn m-b-10" onClick={async () => {
                  const {userEmail, token} = await getAuthTokenOrLogOut();
                  if (token) {
                    startChat(buildChatData(), userEmail, token, inputs.mpxn);
                    setTimeout(init, 100);
                  }
                }}>Start Chat
                </button>
                <button id="goBackLoadingPage" className="secondaryBtn" onClick={init}>Go Back</button>
              </div>
            </div>
          </div>
          <div className="emptySpaceForFixedButtons three-buttons-height"></div>
        </div>
      }
      {
      (!homePageState.automationInProgress && statusScreen === '') && <>
          <div className="commsCheckContainer" id="commsCheckContainer" data-testid="comms-check-c">
            <TextInput label="What's your MPxN?" inputName={'mpxnInput'}
                       error={inputs.mpxnError} kind="MPxN" value={inputs.mpxn} changeFunc={handleMpxnChange}/>
            <TextInput label="What's your MSN?" inputName={'msnInput'} error={inputs.msnError}
                       kind="MSN" value={inputs.msn} changeFunc={handleMsnChange}/>
            <div className="information">
              <p>No MSN? Please Select "Checking site details"</p>
            </div>
            <div className="btn-flex-container buttons-fixed">
              <div className="wrap-login100 chatBtns">
                <div className="container-login100-form-btn">
                  <button className="primaryBtn primaryButtonEnabled m-b-10" id="startCommsCheck"
                          onClick={async () => {
                            const {token} = await getAuthTokenOrLogOut();
                            if (token) {
                              sendSingleGenerateXmlXrinvXritm(
                                inputs,
                                {
                                  xrinv: {data: [], state: null},
                                  xritm: {data: [], state: null},
                                  xrdlg: {data: [], state: null},
                                },
                                token
                              )
                            }
                          }}>Start Comms Check</button>
                  <button className="secondaryBtn" id="waitingScreenLogout" onClick={async () => {
                    await signOut();
                  }}>Log Out
                  </button>
                </div>
              </div>
            </div>
            <div className="emptySpaceForFixedButtons two-buttons-height"></div>
          </div>
        </>
      }
    </>
  );
}
