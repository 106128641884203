import React, {FC} from 'react';
import './Button.css'

export interface ButtonProps {
  isButtonEnabled?: boolean;
  buttonId: string;
  buttonText: string;
  buttonTheme: ButtonTheme;
  dataTestId?: string;
  onClickHandler: () => void;
}

export enum ButtonTheme {
  PRIMARY = 'primary',
  SECONDARY = 'secondary'
}

function getButtonClassName(buttonTheme: ButtonTheme, isButtonEnabled: boolean) {
  switch (buttonTheme) {
    case ButtonTheme.PRIMARY:
      return ` btn primaryBtn${isButtonEnabled ? ' primaryBtnEnabled' : ''}`;
    default:
      return ' btn secondaryBtn';
  }
}

export const Button: FC<ButtonProps> = ({isButtonEnabled = true, buttonId,buttonText, buttonTheme, onClickHandler, dataTestId}) => {
  return (
    <button className={getButtonClassName(buttonTheme, isButtonEnabled)}
              id={buttonId}
              onClick={onClickHandler}
              disabled={!isButtonEnabled}
              data-testid={dataTestId ?? ''}
    >{buttonText}</button>
  );
}
